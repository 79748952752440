import React from 'react'
import { Box } from '@mui/material'
import { Typography } from '@mui/material'

export function LandingPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h1" align="center">
        We are down
      </Typography>
    </Box>
  )
}
