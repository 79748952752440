import * as dotenv from 'dotenv'
dotenv.config()

import { BrowserRouter } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { Main } from './Main'

function App() {



  return (
      <BrowserRouter>
            <CssBaseline />
            <Main />
      </BrowserRouter>
  )
}

export default App
